<!-- 发布主题 -->
<template>
  <div class="issue">
    <!-- 导航标签 -->
    <div class="flex biao align-items">
      <!-- <div>{{ "当前位置 : " }}</div>
      <div style="opacity: 0">{{ "0" }}</div> -->
      <div class="">{{ "首页" }}</div>
      <div class="zuojian">
        <img
          src="../../assets/img/xinban/zuojian.png"
          class="w-full h-full"
          alt=""
        />
      </div>
      <div class="yanse">{{ "发表新帖" }}</div>
    </div>
    <!-- 表单 -->
    <div class="biaodan" v-if="ishow">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- 诉求类型 -->
        <el-form-item label="留言类型：" prop="leiixng">
          <div class="flex align-items">
            <div
              class="flex align-items cursor_pointer mr100"
              @click="suqiu(0)"
            >
              <div class="flex mr6">
                <img
                  src="../../assets/img/xinban/kong.png"
                  v-if="ruleForm.leiixng != 0"
                  class="yuan"
                  alt=""
                />
                <img
                  src="../../assets/img/xinban/yixuan.png"
                  v-else
                  class="yuan"
                  alt=""
                />
              </div>
              <div>{{ "咨询" }}</div>
            </div>
            <div
              class="flex align-items cursor_pointer mr100"
              @click="suqiu(2)"
            >
              <div class="flex mr6">
                <img
                  src="../../assets/img/xinban/kong.png"
                  v-if="ruleForm.leiixng != 2"
                  class="yuan"
                  alt=""
                />
                <img
                  src="../../assets/img/xinban/yixuan.png"
                  v-else
                  class="yuan"
                  alt=""
                />
              </div>
              <div>{{ "建议" }}</div>
            </div>
            <div class="flex align-items cursor_pointer" @click="suqiu(1)">
              <div class="flex mr6">
                <img
                  src="../../assets/img/xinban/kong.png"
                  v-if="ruleForm.leiixng != 1"
                  class="yuan"
                  alt=""
                />
                <img
                  src="../../assets/img/xinban/yixuan.png"
                  v-else
                  class="yuan"
                  alt=""
                />
              </div>
              <div>{{ "投诉" }}</div>
            </div>
          </div>
        </el-form-item>
        <!-- 诉求单位 -->
        <el-form-item label="受理单位：" prop="danwei">
          <div class="danwei flex">
            <div class="fudong" @click="dan"></div>
            <el-input
              v-model="ruleForm.danwei"
              placeholder="点击选择受理单位"
              :disabled="true"
            ></el-input>
            <!-- <div class="el-icon-success"></div> -->
            <div class="tishi">
              <div class="tips">{{ "注：可选择多家受理单位" }}</div>
            </div>
          </div>
        </el-form-item>
        <!-- 诉求标题 -->
        <el-form-item label="留言标题：" prop="state2">
          <div class="flex biaotimu">
            <el-col :span="12">
              <el-autocomplete
                class="inline-input"
                v-model.trim="ruleForm.state2"
                :fetch-suggestions="querySearch"
                placeholder="填写留言标题"
                :trigger-on-focus="false"
                @input="handleSelect"
              ></el-autocomplete>
            </el-col>
            <div class="tishi">
              <div class="tips">{{ "注：内容标题，限制在50字以内" }}</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="上传图片：" v-if="tupianyincnag()">
          <div class="w-full flex flex-wrap">
            <!-- 模板 -->
            <!-- @click="xuantu(index)" -->
            <div
              class="tuImg"
              v-for="(item, index) in $store.state.videImg"
              :key="index"
              :class="{ bian: item.id == true }"
              v-if="item.geshi == '图片'"
            >
              <!-- <img :src=""  alt="" /> -->
              <div class="flex align-items">
                <div class="xuantuan">
                  <el-checkbox
                    v-model="item.st"
                    @change="chatuquan(index)"
                  ></el-checkbox>
                </div>
                <div class="tupiandd">
                  <el-image
                    class="w-full"
                    style="height: 90px; display: flex; justify-content: center"
                    :src="item.di"
                    :preview-src-list="tuyunlan($store.state.videImg)"
                  ></el-image>
                </div>
              </div>
              <div class="_close" @click="close(index)">
                <img
                  src="../../assets/img/xinban/close-circle.png"
                  class="w-full h-full"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- 全选 确定 -->
          <div class="flex quancharu">
            <div>
              <el-checkbox v-model="tuquanxuan" @change="tupianquan"
                >全选</el-checkbox
              >
            </div>
            <el-button
              @click.stop="charuimg"
              class="anniu"
              type="primary"
              size="mini"
              plain
              >插入所选图片</el-button
            >
          </div>
        </el-form-item>

        <el-form-item label="上传视频：" v-if="shipinyincnag()">
          <div class="w-full flex flex-wrap">
            <!-- 模板 -->
            <!-- @click="xuantu(index)" -->
            <div
              class="tuImg"
              v-for="(item, index) in $store.state.videImg"
              :key="index"
              :class="{ bian: item.id == true }"
              v-if="item.geshi == '视频'"
            >
              <div class="flex align-items">
                <div class="xuantuan">
                  <el-checkbox
                    v-model="item.st"
                    @change="chashiquan(index)"
                  ></el-checkbox>
                </div>
              </div>
              <div class="tupiandd">
                <video
                  :src="item.di"
                  style="max-height: 90px"
                  class="w-full"
                  alt=""
                ></video>
              </div>
              <div class="_close" @click="close(index)">
                <img
                  src="../../assets/img/xinban/close-circle.png"
                  class="w-full h-full"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- 全选 确定 -->
          <div class="flex quancharu">
            <div>
              <el-checkbox v-model="shixuanze" @change="shipinxuan"
                >全选</el-checkbox
              >
            </div>
            <el-button
              @click="charushi"
              class="anniu"
              type="primary"
              size="mini"
              plain
              >插入所选视频</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="附件：" v-if="fuzhanlist != 0">
          <div class="w-full flex column">
            <!-- 模板 -->
            <div
              v-for="(item, index) in fuzhanlist"
              :key="index"
              :title="item"
              class="kafijian align-items"
            >
              <div class="tubufen">{{ fuchang(item) }}</div>
              <div class="wenshan" @click="wenshan(index)">{{ "删除" }}</div>
            </div>
          </div>
        </el-form-item>

        <!-- 富文本内容 -->
        <el-form-item label="留言内容：" prop="fuwenben">
          <div class="fwb" :class="{ fwbgaodu: liulist.length != 0 }">
            <wangEditor
              v-model="ruleForm.fuwenben"
              :key="cishu"
              :value="fwb"
              ref="editor"
            ></wangEditor>
            <div class="xuhaun"></div>
            <div class="gengchang" @click="jiachang"></div>
          </div>
          <div class="flex space-between zhu">
            <div class="yulanbtn" @click="kaiyan">预览</div>
            <div class="zhuyi" v-if="$store.state.pvide != 1">
              注:1.点击底部灰色边框可增加富文本框的高度,
              {{ "2.上传时请注意:图片最大为5M,视频最大为20M" }}
            </div>
            <div class="zhuyi" v-else>
              注:1.点击底部灰色边框可增加富文本框的高度,
              {{ "2.上传时请注意:图片最大为5M,视频最大为100M" }}
            </div>
          </div>
        </el-form-item>
        <!-- 联系人姓名 -->
        <el-form-item label="联系人：" prop="lianxi">
          <div class="lianxi">
            <el-input
              v-model="ruleForm.lianxi"
              placeholder="填写联系人"
            ></el-input>
          </div>
        </el-form-item>
        <!-- 联系人电话 -->
        <el-form-item label="联系电话：" prop="dianhua">
          <div class="dianhua lianxi">
            <el-input
              v-model="ruleForm.dianhua"
              placeholder="填写手机号码"
            ></el-input>
          </div>
        </el-form-item>
        <!-- 验证码 -->
        <el-form-item label="短信验证码：" prop="yanzheng">
          <div class="yanzheng flex">
            <el-input
              v-model="ruleForm.yanzheng"
              placeholder="填写验证码"
            ></el-input>
            <el-button type="danger" v-if="daoji == 0" @click="yanzhengma"
              >发送验证码</el-button
            >
            <el-button type="info" disabled v-else
              >发送验证码{{ daoji }}</el-button
            >
          </div>
        </el-form-item>
        <!-- 图形验证 -->
        <el-form-item label="图形验证：" prop="tuxing">
          <div class="yanzheng flex">
            <el-input
              v-model.trim="ruleForm.tuxing"
              placeholder="请输入图片中的内容"
            ></el-input>
            <div class="tuxing" title="点击切换图片">
              <VerificationCode
                :changeCode.sync="identifyCode"
              ></VerificationCode>
            </div>
          </div>
        </el-form-item>
        <!-- 显示方式 -->
        <el-form-item label="显示方式：" prop="xianshi">
          <div class="xianshi flex align-items">
            <div
              class="flex align-items cursor_pointer mr100"
              @click="ruleForm.xianshi = 1"
            >
              <div class="flex mr6">
                <img
                  src="../../assets/img/xinban/kong.png"
                  v-if="ruleForm.xianshi != 1"
                  class="yuan"
                  alt=""
                />
                <img
                  src="../../assets/img/xinban/yixuan.png"
                  v-else
                  class="yuan"
                  alt=""
                />
              </div>
              <div>{{ "公开" }}</div>
            </div>
            <div
              class="flex align-items cursor_pointer mr100"
              @click="ruleForm.xianshi = 0"
            >
              <div class="flex mr6">
                <img
                  src="../../assets/img/xinban/kong.png"
                  v-if="ruleForm.xianshi != 0"
                  class="yuan"
                  alt=""
                />
                <img
                  src="../../assets/img/xinban/yixuan.png"
                  v-else
                  class="yuan"
                  alt=""
                />
              </div>
              <div>{{ "不公开" }}</div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <!-- 右边搜索结果 -->
      <div class="guanjian" v-if="liulist.length != 0">
        <div class="xiangguan">
          <!-- 头部 -->
          <div class="liuyan">{{ "相关留言" }}</div>
          <div class="w-full">
            <!-- 模板 -->
            <div
              class="yihang"
              v-for="(item, index) in liulist"
              :key="index"
              @click="xiangqing(item.cardsalt)"
            >
              <div class="icon">
                <img src="../../assets/img/one/搜索.png" alt="" />
              </div>
              <div v-html="gbh(item.title)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 提交按钮 -->
    <div class="flex justify-content tijiao" v-if="ishow">
      <div class="flex">
        <el-button type="info" :disabled="!canfabu" @click="caogao">保存草稿</el-button>
        <el-button type="primary" :disabled="!canfabu" @click="tijiaoBtn('ruleForm')"
          >提交</el-button
        >
      </div>
    </div>
    <!-- 弹框 -->
    <div class="fabuqueding" v-if="dialogTableVisible">
      <el-dialog title="" :visible.sync="dialogTableVisible">
        <div class="flex tishiyu">
          <div class="closeeee" @click="dialogTableVisible = false">
            <i class="el-icon-close"></i>
          </div>
          <div class="flex align-items">
            <div class="tishiicon">
              <img
                src="../../assets/img/xinban/tishiicon.png"
                class="w-full h-full"
                alt=""
              />
            </div>
            <div>{{ "您是否确定发布该贴？" }}</div>
          </div>
        </div>
        <div class="flex btn">
          <div class="flex">
            <el-button type="info" @click="caogao">保存草稿</el-button>
            <el-button type="primary" @click="shangchuan">提交</el-button>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 提交成功页面 ------------------------------>
    <div class="success_page flex" v-if="!ishow">
      <div class="success_title tac">
        {{ fcnum == 0 ? "提交成功" : "保存成功" }}
      </div>
      <div class="flex daojishi">
        <div>{{ shimiao }}</div>
        <div>{{ "秒后回到个人中心" }}</div>
      </div>
      <!-- <div class="success_thanks tac">谢谢，您的帖子已经提交</div>
      <div class="success_thanks tac mt">
        <span class="success_">办件编号：</span>
        <span class="success_bianhao">ydq_ysqgk2023600911</span>
        <span class="success_save">保存本地</span>
      </div> -->
      <div class="flex jcc">
        <!-- @click="gotohome" 回到首页 -->
        <div class="success_btn tac" @click="jb" v-if="fcnum != 0">
          继续当前帖子编辑
        </div>
        <div class="success_btn tac" @click="refresh">发表新帖</div>
      </div>
    </div>
    <!-- end------------------------------------- -->
    <el-dialog title="" :visible.sync="danweixuanze">
      <div class="bumencha">
        <div class="shouli">
          <div class="closee" @click="danweixuanze = false">
            <i class="el-icon-close"></i>
          </div>
          <!-- 模板 -->
          <div class="list flex" v-for="(item, index) in list" :key="index">
            <div class="dalei white-space">{{ item.name }}</div>
            <div class="xiaofen flex">
              <div
                class="flex gezi cursor_pointer"
                v-for="(res, num) in item.child"
                :key="num"
                @click.stop="bumen(res.name, res.id, index, num)"
              >
                <div class="flex">
                  <div
                    :class="{ lanzi: res.xuan == 1 }"
                    v-if="res.biaohong != ''"
                    v-html="res.biaohong"
                  ></div>
                  <div :class="{ lanzi: res.xuan == 1 }" v-else>
                    {{ res.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 查询部门 -->
          <div class="flex chaxun space-between">
            <div class="flex align-items">
              <div class="flex zimu">
                <div
                  class="mu"
                  v-for="(item, index) in zimu"
                  @click="shuruzimu(item)"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
              <div class="sousu">
                <div class="w-full h-full">
                  <el-input
                    class="inline-input"
                    v-model="input"
                    placeholder="您可输入要查找的单位"
                  >
                  </el-input>
                  <div class="fang">
                    <div class="fangda" @click="tgj"></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 确定 -->
            <div>
              <el-button type="primary" @click="danweixuanze = !danweixuanze"
                >确认</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 上传弹框 -->
    <div class="shangchua" v-if="benstu != 0">
      <!-- 卡片头部 -->
      <div class="flex align-items tou space-between">
        <div class="h-full">
          <div class="flex fuqilai">
            <div
              class="shangchuan"
              :class="{ meixia: shanum == 0 }"
              @click="shanum = 0"
            >
              {{ "上传图片" }}
            </div>
            <div
              class="shangchuan"
              :class="{ meixia: shanum == 1 }"
              @click="shanum = 1"
            >
              {{ "上传视频" }}
            </div>
            <div
              class="shangchuan"
              :class="{ meixia: shanum == 2 }"
              @click="shanum = 2"
            >
              {{ "上传附件" }}
            </div>
          </div>
        </div>
        <!-- 关闭开关 -->
        <div @click="guantan" class="closeg el-icon-error"></div>
      </div>
      <!-- 卡片内容 -->
      <div class="shenti">
        <!-- 上传图片 -->
        <div class="tupianka" v-if="shanum == 0">
          <el-upload
            :show-file-list="false"
            ref="upload"
            class="upload-demo"
            :action="$store.state.uploadurl + '/api/common/upload'"
            :headers="token()"
            :multiple="true"
            :on-success="lst"
            :before-upload="tucha"
          >
            <div class="flex align-items">
              <el-button size="small" type="primary">{{
                "选择图片上传"
              }}</el-button>
              <span class="yangshi">
                <span>{{ "图片大小:" }}</span>
                <span class="zy">{{ "小于5M" }}</span>
                <span>,{{ "可上传类型:" }}</span>
                <span class="zy">{{ " jpg, jpeg, gif, png,webp,jfif " }}</span>
              </span>
            </div>
          </el-upload>
          <!-- <div class="hbss"></div> -->
          <!-- 图片展示 -->
          <div class="flex kazhan">
            <!-- 模板 -->
            <div
              v-for="(item, index) in $store.state.videImg"
              :key="index"
              class="katu"
              v-if="item.geshi == '图片'"
            >
              <div class="tubufen" @click="cahru(item.di, 'img')">
                <img :src="item.di" class="w-full" alt="" />
              </div>
              <div class="tushan" @click="close(index)">{{ "删除" }}</div>
            </div>
          </div>
        </div>
        <!-- 上传视频 -->
        <div class="tupianka" v-else-if="shanum == 1">
          <div class="flex align-items" v-if="$store.state.pvide != 0">
            <ossupload
              :btn="ossbtn"
              position="newsPicture"
              :styleType="1"
              :showFile="saveParams.sImgUrl"
              v-model="saveParams.sImgUrl"
            ></ossupload>
            <span class="yangshi">
              <span>{{ "视频大小:" }}</span>
              <span class="zy">{{ "小于100M" }}</span>
              <span>,{{ "可上传类型:" }}</span>
              <span class="zy">{{ " mp4,avi " }}</span>
            </span>
          </div>
          <el-upload
            v-else
            :show-file-list="false"
            ref="upload"
            class="upload-demo"
            :action="$store.state.uploadurl + '/api/common/upload'"
            :headers="token()"
            :multiple="true"
            :on-success="sps"
            :before-upload="shicha"
          >
            <div class="flex align-items">
              <el-button size="small" type="primary">{{
                "选择视频上传"
              }}</el-button>
              <span class="yangshi">
                <span>{{ "视频大小:" }}</span>
                <span class="zy">{{ "小于20M" }}</span>
                <span>,{{ "可上传类型:" }}</span>
                <span class="zy">{{ " mp4,avi " }}</span>
              </span>
            </div>
          </el-upload>
          <!-- <div class="hbss"></div> -->
          <!-- 视频展示 -->
          <div class="flex kazhan">
            <!-- 模板 -->
            <div
              v-for="(item, index) in $store.state.videImg"
              :key="index"
              class="katu"
              v-if="item.geshi == '视频'"
            >
              <div class="tubufen" @click="cahru(item.di, 'video')">
                <video :src="item.di" class="w-full" alt=""></video>
              </div>
              <div class="tushan" @click="close(index)">{{ "删除" }}</div>
            </div>
          </div>
        </div>
        <!-- 上传附件 -->
        <div class="tupianka" v-else-if="shanum == 2">
          <el-upload
            :show-file-list="false"
            ref="upload"
            class="upload-demo"
            :action="$store.state.uploadurl + '/api/common/upload'"
            :headers="token()"
            :multiple="true"
            :on-success="wes"
            :before-upload="wencha"
          >
            <el-button size="small" type="primary">{{
              "选择文件上传"
            }}</el-button>
            <span class="yangshi">
              <span>{{ "文件大小:" }}</span>
              <span class="zy">{{ "小于10M" }}</span>
              <span>,{{ "可上传类型:" }}</span>
              <span class="zy">{{ " doc,docx,xls,xlsx " }}</span>
            </span>
          </el-upload>
          <!-- <div class="hbss"></div> -->
          <!-- 视频展示 -->
          <div class="flex kazhan column">
            <!-- 模板 -->
            <div
              v-for="(item, index) in fuzhanlist"
              :key="index"
              :title="item"
              class="kafijian align-items"
            >
              <div class="tubufen">{{ fuchang(item) }}</div>
              <div class="wenshan" @click="wenshan(index)">{{ "删除" }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 确定按钮 -->
      <div class="dibuka">
        <div class="jinggao beizhu" v-if="shanum == 0">
          备注: 点击图片,将图片插入富文本
        </div>
        <div class="jinggao beizhu" v-else-if="shanum == 1">
          备注: 点击视频,将视频插入富文本
        </div>
        <div class="jinggao" v-else-if="shanum == 2"></div>
        <div class="kabtn" @click="guantan">{{ "确定" }}</div>
      </div>
    </div>
    <!-- 预览遮罩层 -->
    <div class="zhaozi" v-if="yunum == 1">
      <div class="gund">
        <div class="carddd">
          <div class="closeeee" @click="yunum = 0">
            <i class="el-icon-close"></i>
          </div>
          <div class="w-full xianq">
            <div class="w-full xian_head flex">
              <div class="flex justify-content align-items zuo"  :style="{backgroundImage: 'url(' + bg2 + ')' }" style="background-size: 100% 100%;">
                <div class="mr33">
                  <span ref="import">{{ "查看 :" }}</span>
                  <span class="opacity">我</span>
                  <span class="yanse">{{ 0 }}</span>
                </div>
                <div>
                  <span>{{ "回复 :" }}</span>
                  <span class="opacity">我</span>
                  <span class="yanse">{{ 0 }}</span>
                </div>
              </div>
              <div class="you flex1">
                <div class="zuiyou">
                  <span class="zixu">
                    <span class="zi" v-if="ruleForm.leiixng == 0">
                      【{{ "咨询" }}】
                    </span>
                    <span class="hong" v-else-if="ruleForm.leiixng == 1">
                      【{{ "投诉" }}】
                    </span>
                    <span class="lvz" v-else-if="ruleForm.leiixng == 2">
                      【{{ "建议" }}】
                    </span>
                  </span>
                  <span class="danweis">【{{ ruleForm.danwei }}】</span>
                </div>
                <div class="biaoti">{{ ruleForm.state2 }}</div>
              </div>
            </div>
            <!-- 楼主 -->
            <div class="louzhu flex w-full">
              <div class="zuo flex">
                <div class="touxinag cursor_pointer">
                  <img
                    src="../../assets/img/one/weizhiyonghu.png"
                    class="w-full h-full"
                    alt=""
                  />
                </div>
                <div class="ming">
                  {{ wanzhang == null ? "" : wanzhang.usename }}
                </div>
                <div class="flex ziliao align-items">
                  <div class="flex column align-items">
                    <div>{{ 0 }}</div>
                    <div class="fontone">{{ "主题" }}</div>
                  </div>
                  <div class="xian"></div>
                  <div class="flex column align-items">
                    <div>{{ 0 }}</div>
                    <div class="fontone">{{ "回帖" }}</div>
                  </div>
                </div>
                <div class="flex align-items mt15"></div>
                <img
                  src="../../assets/img/xinban/louzhu.png"
                  class="louzhubiaoji"
                  alt=""
                />
              </div>
              <div class="you flex1 flex column">
                <!-- 发布信息 -->
                <div class="fabuxinxi flex align-items space-between">
                  <div class="fontone">{{ `发表于${shijians()}PC端` }}</div>
                  <div class="fontTwo"></div>
                </div>
                <!-- 文章主题 -->
                <div class="wenzhanzhuti flex">
                  <div>
                    <!-- html标签 -->
                    <div v-html="yuhtml"></div>
                    <!-- 图片视频 -->
                    <div style="margin-top: 20px">
                      <!-- 附件 -->
                      <div
                        class="flex ful"
                        v-if="
                          wanzhang == null
                            ? ''
                            : wanzhang.appletfiles.length != 0
                        "
                      >
                        <div>{{ "附件 :" }}</div>
                        <div class="fuliebiao">
                          <div
                            @click="tuxiazia(item.url)"
                            class="wenjianming"
                            v-for="(item, index) in wanzhang == null
                              ? ''
                              : wanzhang.appletfiles"
                            :key="index"
                          >
                            <span class="hov">{{ item.filename }}</span>
                            <span class="xiazi">({{ item.size }})</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 官方回复 -->
                    <div class="flex w-full justify-content mt33"></div>
                  </div>
                  <div class="flex caozuo">
                    <div></div>
                    <div class="flex align-items">
                      <!-- 收藏 -->
                      <div class="bianju">
                        <div class="flex align-items">
                          <img
                            src="../../assets/img/xinban/shoucang.png"
                            class="icon"
                            alt=""
                          />
                          <!-- <img src="../../assets/img/one/yishoucnag.png" class="icon" alt=""> -->
                          <div>{{ "收藏" }}</div>
                        </div>
                      </div>
                      <!-- 点赞 -->
                      <div class="bianju">
                        <div class="flex align-items">
                          <img
                            src="../../assets/img/xinban/dainzan.png"
                            class="icon"
                            alt=""
                          />
                          <div>{{ "点赞" }}</div>
                        </div>
                      </div>
                      <!-- 举报 -->
                      <div class="bianju">
                        <div class="flex align-items">
                          <img
                            src="../../assets/img/xinban/jubao.png"
                            class="icon"
                            alt=""
                          />
                          <div class="yanse">{{ "举报" }}</div>
                        </div>
                      </div>
                      <div class="bianju">
                        <div class="yanse">{{ "回复" }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 错误图片遮罩层 -->
    <div class="zhaozi" v-if="cuonum == 1">
      <div class="dabaibai" v-if="cuoimg.length != 0">
        <div class="closeeee" @click="cuonum = 0">
          <i class="el-icon-close"></i>
        </div>
        <!-- 错误图片 -->
        <div>
          <div class="cuofont">
            以下图片存在格式错误,请删除后通过上传图片功能,重新上传!
          </div>
          <div class="cuotu">
            <!-- 错误图片模板 -->
            <div class="cuoshi" v-for="(item, index) in cuoimg" :key="index">
              <img class="w-full" :src="item" alt="" />
            </div>
          </div>
        </div>
        <!-- 错误视频 -->
        <div style="margin-top: 20px" v-if="cuovideo.length != 0">
          <div class="cuofont">
            以下图视频存在格式错误,请删除后通过上传视频功能,重新上传!
          </div>
          <div class="cuotu">
            <!-- 错误视频模板 -->
            <div class="cuoshi" v-for="(item, index) in cuovideo" :key="index">
              <video :src="item" class="w-full" alt=""></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入富文本
import Bg2 from '@/assets/img/xinban/tztopbg.png'
import wangEditor from "./wangEditor/index.vue";
// import wangEditor from "./wangEditor/fu.vue";
// 图形验证
import VerificationCode from "../tuxingyanzheng/index.vue";
import API from "@/api/modules/issue.js";
import http from "@/api/modules/http.js";
import Cookies from "js-cookie";
import ossupload from "../upload/index.vue";
import { MessageBox } from 'element-ui'
// 时间
var timess;
var yantiem;
// 节流阀时间
var jietime;
var shim;
export default {
  components: {
    wangEditor,
    VerificationCode,
    ossupload,
  },
  data() {
    return {
      bg2: Bg2,
      ossbtn: "选择视频上传",
      saveParams: {
        sImgUrl: "",
      },
      mb: "", //文件大小
      cuoimg: [], //错误的图片
      cuovideo: [], //错误的视频
      cuonum: 0,
      isyanzhen: 0, //是否点击过验证码
      caox: [], //草稿箱线上的图片
      buarrname: [],
      buarrid: [],
      caoid: "", //草稿id
      fcnum: 0, //发帖还是草稿
      yunum: 0, // 预览的遮罩层
      yuhtml: "", //预览的html
      liulist: [], //留言列表
      huche: true, //互斥锁
      shimiao: 0, //十秒倒计时
      daoji: 0, //发送验证码的倒计时
      tulist: [], //上传时保存图片的数组
      shilist: [], //上传时保存视频的数组
      fujianname: "", //附件名 附件上传前可以得到name,成功后赋给fuzhanlist数组进行展示
      fulist: [], //附件数组
      fuzhanlist: [], //附件展示的数组
      shanum: 0, //富文本点击图片,进入上传卡片 切换上传图片和上传视频
      benstu: 0, //本地存储的值
      ishow: true,
      danweixuanze: false, //单位选择
      list: [], //受理单位
      zimu: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      input: "", //搜索框
      danweiId: "", //选择单位的id
      ruleForm: {
        leiixng: 0, //述求类型
        danwei: "", //诉求单位
        state2: "", //标题
        fuwenben: "", //内容
        lianxi: "", //联系人
        dianhua: "", //电话
        yanzheng: null, //获取验证码
        tuxing: null, //图形验证
        xianshi: 1, //0 不公开 1公开
      },
      identifyCode: "", //自动生成的图形验证码
      cishu: 0, //富文本清空
      kuangNum: [], //选中的首图,默认第一张
      restaurants: [], // 输入后建议
      bjq: "", // 编辑器内容
      fwb: "",
      shouYan: "", //手机验证码
      dialogTableVisible: false, //弹框开关
      rules: {
        leiixng: [{ required: true, trigger: "blur" }],
        xianshi: [{ required: true, trigger: "blur" }],
        state2: [
          { required: true, message: "请输入留言标题", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        lianxi: [
          { required: true, message: "请填写联系人姓名", trigger: "blur" },
        ],
        dianhua: [
          { required: true, message: "请填写联系人电话", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        fuwenben: [{ required: true, message: "请输入内容", trigger: "blur" }],
        danwei: [
          { required: true, message: "请选择受理的单位", trigger: "change" },
        ],
        yanzheng: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          // { required: true, trigger: "blur", validator: this.shouji },
        ],
        tuxing: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { required: true, trigger: "blur", validator: this.validatePass },
        ],
      },
      videImg: [],
      options: [], //获取区域
      shoutuList: [], //首图
      wanzhang: null, //帖子详情
      bianjis: 0, //编辑状态 0是发表新帖,1是编辑状态
      loading: null, //等待效果
      shisuo: true, //视频锁
      //图片全选
      tuquanxuan: false,
      shixuanze: false,
      canfabu:true,
    };
  },
  watch: {
    identifyCode: function (vla, old) {
      // console.log(vla, "萨达");
    },
    "$store.state.videImg": function (vla, old) {
      this.videImg = vla;
      // console.log(this.videImg, "num");
      // this.getVideoBase64(this.videoList[0])
    },
    input: async function (vla, old) {
      // await this.unitList()
      var str = vla.toUpperCase();
      this.list.forEach((item, index) => {
        this.biao(item.child, str, index);
      });
    },
    "ruleForm.tuxing": function (vla, old) {
      // var str = vla.toUpperCase();
      var str = vla;
      this.ruleForm.tuxing = str;
    },
    "ruleForm.state2": function (vla, old) {
      if (vla == "") {
        this.liulist = [];
      }
    },
  },
  async mounted() {
    // 离开判定
    this.$store.state.likai = 1;
    // 先通过mounted钩子函数添加窗口关闭事件的监听
    window.addEventListener("beforeunload", this.handleWindowClose);

    localStorage.setItem("path", this.$route.path);
    this.$store.state.videImg = [];
    // 先判定是否有token 如果为空
    // window.localStorage.setItem('sendebang', a)
    let aaa = window.localStorage.getItem("sendebang");
    // window.localStorage.removeItem('sendebang')
    // if (aaa == undefined) {
    //   this.$store.state.path = this.$route.path;
    //   localStorage.setItem("path", this.$route.path);
    //   this.$router.push("/login");
    //   return;
    // }
    if(this.$store.state.mytie.ruleForm){
      this.ruleForm.fuwenben = this.$refs.editor.GetTheHTML();
      this.ruleForm=this.$store.state.mytie.ruleForm
      this.danweiId= this.$store.state.mytie.danweiId
      console.log(this.$store.state.mytie.ruleForm)
      this.$refs.editor.huodeGTML( this.$store.state.mytie.ruleForm.fuwenben);
    }

    
    await this.unitList();
    // 更改诉求类型
    this.ruleForm.leiixng = this.$store.state.leiixng;
    this.restaurants = this.loadAll();
    this.region();
    // 进入编辑状态
    if (this.$store.state.loginMessage.EditStatus == 1) {
      this.bianjis = 1;
      // this.$store.state.bianji = 0;
      this.tiezi();
    }
    // 进入前因先关闭,防止刷新页面时打开
    sessionStorage.setItem("stu", 0);
    this.bendij();
  },
  methods: {
    tupianyincnag() {
      let aa = 1;
      let bb = [];
      this.$store.state.videImg.forEach((item) => {
        bb.push(item.geshi);
      });
      aa = bb.indexOf("图片");
      if (aa != -1) {
        return true;
      } else {
        return false;
      }
    },
    shipinyincnag() {
      let aa = 1;
      let bb = [];
      this.$store.state.videImg.forEach((item) => {
        bb.push(item.geshi);
      });
      aa = bb.indexOf("视频");
      if (aa != -1) {
        return true;
      } else {
        return false;
      }
    },
    // 视频正反选
    shipinxuan(e) {
      // console.log(e,'数据');
      if (e == true) {
        this.$store.state.videImg.forEach((item) => {
          if (item.geshi == "视频") {
            item.st = true;
          }
        });
      } else {
        this.$store.state.videImg.forEach((item) => {
          if (item.geshi == "视频") {
            item.st = false;
          }
        });
      }
      let a = this.$store.state.videImg;
      this.$store.state.videImg = [];
      this.$store.state.videImg = a;
    },
    // 插入所选视频
    charushi() {
      this.$confirm("请确认在富文本中已选择号插入点来插入视频", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.state.videImg.forEach((item) => {
            if (item.geshi == "视频") {
              if (item.st == true) {
                this.$refs.editor.charushi(item.di);
                item.st = false;
              }
            }
          });
          this.shixuanze = false;
        })
        .catch(() => {});
    },
    // 插入所选图片
    charuimg() {
      this.$confirm("请确认在富文本中已选择号插入点来插入图片", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.state.videImg.forEach((item) => {
            if (item.geshi == "图片") {
              if (item.st == true) {
                this.$refs.editor.charutu(item.di);
                item.st = false;
              }
            }
          });
          this.tuquanxuan = false;
        })
        .catch(() => {});
    },
    // 视频
    chashiquan(num) {
      let a = 0;
      let c = [];
      // 剔除图片
      this.$store.state.videImg.forEach((item) => {
        if (item.geshi == "视频") {
          c.push(item);
        }
      });
      // 只看视频
      c.forEach((item) => {
        if (item.st == false) {
          a = 1;
        }
      });
      if (a == 1) {
        this.shixuanze = false;
      } else {
        this.shixuanze = true;
      }
      let b = this.$store.state.videImg;
      this.$store.state.videImg = [];
      this.$store.state.videImg = b;
    },
    // 图片
    chatuquan(num) {
      let a = 0;
      let c = [];
      // 剔除视频
      this.$store.state.videImg.forEach((item) => {
        if (item.geshi == "图片") {
          c.push(item);
        }
      });
      // 只看图片
      c.forEach((item) => {
        if (item.st == false) {
          a = 1;
        }
      });

      if (a == 1) {
        this.tuquanxuan = false;
      } else {
        this.tuquanxuan = true;
      }
      let b = this.$store.state.videImg;
      this.$store.state.videImg = [];
      this.$store.state.videImg = b;
    },
    // 图片正反选
    tupianquan(e) {
      // console.log(e,'数据');
      if (e == true) {
        this.$store.state.videImg.forEach((item) => {
          if (item.geshi == "图片") {
            item.st = true;
          }
        });
      } else {
        this.$store.state.videImg.forEach((item) => {
          if (item.geshi == "图片") {
            item.st = false;
          }
        });
      }
      let a = this.$store.state.videImg;
      this.$store.state.videImg = [];
      this.$store.state.videImg = a;
    },
    handleWindowClose(event) {
      // 兼容不同浏览器的事件对象获取方式
      event = event || window.event;
      // 设置提示信息
      const message = "你好";
      event.returnValue = message;
      return message;
    },
    // 判断是否有top提示
    //  gf(str) {
    //   let a = str.split(",");
    //   if (a.length > 2) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // },
    // // 部门长度
    // buchandu(str) {
    //   let a = str.split(",");
    //   if (a.length <= 2) {
    //     return a.toString();
    //   } else {
    //     return a[0] + "," + a[1] + "...";
    //   }
    // },
    shijians() {
      var myDate = new Date();
      let a = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
      let b = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let c = myDate.getDate(); //获取当前日(1-31)

      return a + "年" + b + "月" + c + "日";
    },
    // 预览
    kaiyan() {
      this.yuhtml = this.$refs.editor.GetTheHTML();
      if (
        this.yuhtml ==
        '<p style="font-size:16px !important" data-we-empty-p=""><br/></p>'
      ) {
        this.$message({
          message: "请输入内容!",
          center: true,
          type: "warning",
        });
        return;
      }
      this.guantan();
      this.yunum = 1;
      // 获取html
      // console.log(this.yuhtml);
    },
    jiachang() {
      this.$refs.editor.schang();
    },
    // 跳转至详情
    xiangqing(id) {
      this.$store.state.loginMessage.cardsalt = id;
      // localStorage.setItem("cardsalt", id);
      localStorage.setItem("cardsalttt", id);
      let times = setTimeout(() => {
        let routeData = this.$router.resolve({
          name: "details",
          query: this.$store.state.loginMessage,
        });
        window.open(routeData.href, "_blank");
      }, 100);

      // this.jump('details', this.$store.state.loginMessage)
    },
    // 右边相关留言关键字标红
    gbh(val) {
      var str = val;
      let s = this.ruleForm.state2;
      //复制过来的数据中间带有空格的，去除中间空格，字符之间用逗号隔开
      let inputvalue = s.replace(/\ +/g, ","); //把空格分开的字符串转换成以逗号分割
      let keyWordArr2 = inputvalue.split(","); //把字符串转换成数组
      // 当对话内容中有包含搜索框中的字符串时
      if (str && str != "") {
        keyWordArr2.forEach((e) => {
          let regStr = "" + `(${e})`;
          let reg = new RegExp(regStr, "g");
          str = str.replace(reg, '<span style="color:red;">' + e + "</span>");
        });
      }
      return str;
    },
    // 预览图片
    tuyunlan(obj) {
      let a = [];
      obj.forEach((item) => {
        if (item.geshi == "图片") {
          a.push(item.di);
        }
      });
      return a;
    },
    // 点击插入
    cahru(url, str) {
      if (str == "img") {
        this.$refs.editor.charutu(url);
      } else {
        this.$refs.editor.charushi(url);
      }
    },
    // 卡片里文件删除
    wenshan(num) {
      this.fulist.splice(num, 1);
      this.fuzhanlist.splice(num, 1);
    },
    // 文件名长度限制
    fuchang(str) {
      if (str.length > 20) {
        const isExcel = str.split(".")[1];
        return str.slice(0, 17) + "..." + isExcel;
      } else {
        return str;
      }
    },
    // 文件上传前校验
    wencha(e) {
      // console.log(e, '闲情');
      const isSize = e.size / 1024 / 1024;
      if (isSize > 10) {
        this.$message.error("文件大小不能超过10M！");
        return false;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let a = e.name;
      this.fujianname = a;
      let size = e.size;
      let filesize;
      if (size >= 1048576) {
        filesize = (size / 1048576).toFixed(2) + " MB";
      } else if (size >= 1024) {
        filesize = (size / 1048576).toFixed(2) + " KB";
      } else if (size > 0) {
        filesize = size + " b";
      } else {
        filesize = 0;
      }
      this.mb = filesize;
      // console.log(this.fujianname,'文件名');
      const isExcel =
        a.split(".").pop() === "doc" ||
        a.split(".").pop() === "docx" ||
        a.split(".").pop() === "xls" ||
        a.split(".").pop() === "xlsx";
      if (!isExcel) {
        this.loading.close();
        this.$message.error("只能上传doc,docx,xls,xlsx类型的文件!");
        return false;
      }
    },
    // 文件上传成功时
    wes(res) {
      // console.log(res,'文件名');
      this.loading.close();
      if (res.code == 1) {
        this.fulist.push({
          filename: res.data.filename,
          size: this.mb,
          url: res.data.fullurl,
        });
        this.fuzhanlist.push(this.fujianname);
      } else {
        this.$message.error(res.msg);
      }
    },
    // oss视频上传成功
    complete(oss) {
      // console.log(oss,'是接口连接');
      let that = this;
      let url = oss[0].split("?")[0];
      var audio = new Audio(url);
      // 元数据已加载
      audio.addEventListener("loadedmetadata", function (e) {
        let duration = audio.duration;

        //计算时长
        if (duration < 60) {
          // 去除小数点
          let b = Math.trunc(duration);
          if (b < 10) {
            b = "0" + b;
          }
          b = "00" + ":" + b;
          that.zhuan(url, 0, b);
        } else {
          let c = Math.trunc(duration);
          let time_s = c;
          var minute = Math.floor(time_s / 60);
          var rest_seconds = time_s % 60;
          let a = minute + ":" + rest_seconds;
          that.zhuan(url, 0, a);
        }
      });
    },
    // 在卡片里视频上传成功
    sps(res) {
      this.loading.close();
      this.shisuo = true;
      // console.log(res, "上chaun");
      let that = this;
      if (res.code == 1) {
        var audio = new Audio(res.data.fullurl);
        // 元数据已加载
        audio.addEventListener("loadedmetadata", function (e) {
          let duration = audio.duration;

          //计算时长
          if (duration < 60) {
            // 去除小数点
            let b = Math.trunc(duration);
            if (b < 10) {
              b = "0" + b;
            }
            b = "00" + ":" + b;
            that.zhuan(res.data.fullurl, 0, b);
          } else {
            let c = Math.trunc(duration);
            let time_s = c;
            var minute = Math.floor(time_s / 60);
            var rest_seconds = time_s % 60;
            let a = minute + ":" + rest_seconds;
            that.zhuan(res.data.fullurl, 0, a);
          }
        });
        // this.zhuan(res.data.fullurl, 1)
      } else {
        that.$message.error(res.msg);
      }
    },
    // 视频上传前的校验
    shicha(e) {
      if (this.shisuo == false) {
        this.$message.error("视频上传中,请稍等!");
      }
      const isSize = e.size / 1024 / 1024;
      if (isSize > 20) {
        this.$message.error("视频大小不能超过20M！");
        return false;
      }
      // console.log(e, "手机号");

      let a = e.type;
      const isExcel =
        a.split("/").pop() === "mp4" || a.split("/").pop() === "avi";
      // a.split(".")[1] === "gif" ||
      // a.split(".")[1] === "png";
      if (!isExcel) {
        this.$message.error("只能上传mp4,avi类型的图片!");
        return false;
      }
      this.shisuo = false;
      this.loading = this.$loading({
        text: "视频上传中,请稍等...",
      });
    },
    // 图片上传前的校验
    tucha(e) {
      const isSize = e.size / 1024 / 1024;
      if (isSize > 5) {
        this.$message.error("图片大小不能超过5M！");
        return false;
      }
      let a = e.name;
      const isExcel =
        a.split(".").pop() === "jpg" ||
        a.split(".").pop() === "jpeg" ||
        a.split(".").pop() === "gif" ||
        a.split(".").pop() === "webp" ||
        a.split(".").pop() === "jfif" ||
        a.split(".").pop() === "png";
      if (!isExcel) {
        this.$message.error("只能上传jpg,jpeg,gif,png,webp,jfif类型的图片!");
        return false;
      }
    },
    // 上传时得到token
    token() {
      // window.localStorage.setItem('sendebang', a)
      let aaa = window.localStorage.getItem("sendebang");
      // window.localStorage.removeItem('sendebang')
      return { token: aaa };
    },
    // 在卡片里图片上传成功
    lst(res) {
      // console.log(res, "上chaun");
      if (res.code == 1) {
        this.zhuan(res.data.fullurl, 1);
      } else {
        this.$message.error(res.msg);
      }
    },
    zhuan(url, num, time) {
      // console.log(time, "时长");
      if (num == 0) {
        this.$store.state.videImg.push({
          di: url,
          geshi: "视频",
          id: 0,
          shi: time,
        });
      } else {
        this.$store.state.videImg.push({
          di: url,
          geshi: "图片",
          id: 0,
        });
      }
    },
    // 关闭上传弹框,并开始重新监听
    guantan() {
      sessionStorage.setItem("stu", 0);
      this.bendij();
    },
    // 监听本地存储,最蠢的方法,我暂时没有更好的想法(2023/6/10)
    bendij() {
      // 将它异步
      let that = this;
      timess = setTimeout(() => {
        that.benstu = sessionStorage.getItem("stu");
        if (that.benstu == 0) {
          that.bendij();
        }
      }, 100);
    },
    // 标红
    biao(obj, str, xia) {
      let c = [];
      let b = 0;
      let a = 0;
      let d = 0;
      let f = 0;
      let g = "";
      // 判断是文字还是英文 如果返回-1代表不是字母,反之就是字母
      let shouzi = str.slice(0, 1);
      let pan = this.zimu.indexOf(shouzi);
      if (pan != -1) {
        // 英文标红
        obj.forEach((item, index) => {
          // 查询字符在字母里的位置,获取起始下标
          a = item.allnum.indexOf(str, 0);

          if (a != -1) {
            //    console.log(a,item.name,'222');
            // 获取下标
            f = index;
            // console.log(f,'下标');
            // 获取查询的字符窜长度
            b = str.length;
            // 将文字转为数组
            let e = item.name.split("");
            // console.log(e,'0000');
            e.forEach((item, index) => {
              c.push({
                name: item,
                zt: false,
              });
            });
            // 改变状态
            c.forEach((item, index) => {
              if (d == b) {
                return;
              } else {
                d = d + 1;
                c[a].zt = true;
                a = a + 1;
              }
            });
            // 拼接html
            c.forEach((item, index) => {
              if (item.zt == false) {
                g = g + `<span>${item.name}</span>`;
              } else {
                g =
                  g +
                  `<span style=' color: #C90E0E !important;font-weight: 700;'>${item.name}</span>`;
              }
            });
            this.list[xia].child[f].biaohong = g;
            c = [];
            g = [];
            d = 0;
          } else {
            item.biaohong = "";
          }
        });
        // 拼音标红
        // 先将字母全部小写
        let py = str.toLowerCase();
        if (py.length <= 1) {
          return;
        }
        this.list.forEach((item) => {
          item.child.forEach((res) => {
            // 将拼音转为数组
            let a = res.pinying.split(" ");
            let b = res.name.split("");
            // 循环拼音数组
            let e = "";
            // 将拼音组合在一起
            a.forEach((str, num) => {
              if (e == "") {
                e = str;
              } else {
                e = e + str;
              }
            });
            let reg = new RegExp(py);
            let result = reg.test(e);
            let c = "";
            if (result) {
              b.forEach((wenzi) => {
                wenzi = `<span style=' color: #C90E0E !important;font-weight: 700;'>${wenzi}</span>`;
                if (c == "") {
                  c = wenzi;
                } else {
                  c = c + wenzi;
                }
              });
              res.biaohong = c;
            } else {
              res.biaohong = "";
            }
          });
        });
      } else {
        // 中文标红
        let cc = [];
        let bb = 0;
        let aa = 0;
        let dd = 0;
        let ff = 0;
        let gg = "";
        obj.forEach((item, index) => {
          // 查询文字在里的位字符里的其实位置置,获取起始下标
          aa = item.name.indexOf(str, 0);
          // 如果aa不等于-1则表示查到有内容
          if (aa != -1) {
            // 获取下标
            ff = index;
            // 获取查询的字符窜长度
            bb = str.length;
            // 将文字转为数组
            let ee = item.name.split("");
            // 将它每个文字都拆分
            ee.forEach((item, index) => {
              cc.push({
                name: item,
                zt: false,
              });
            });
            // 对比文字改变状态
            cc.forEach((item, index) => {
              if (dd == bb) {
                return;
              } else {
                dd = dd + 1;
                cc[aa].zt = true;
                aa = aa + 1;
              }
            });
            // 拼接html
            cc.forEach((item, index) => {
              if (item.zt == false) {
                gg = gg + `<span>${item.name}</span>`;
              } else {
                gg =
                  gg +
                  `<span style=' color: #C90E0E !important;font-weight: 700;'>${item.name}</span>`;
              }
            });
            this.list[xia].child[ff].biaohong = gg;
            cc = [];
            gg = [];
            dd = 0;
          } else {
            item.biaohong = "";
          }
        });
      }
    },
    //首图长度
    tuchang() {
      let a = this.$store.state.videImg.length;
      let b = typeof a;
      if (typeof a == "number") {
        return a;
      } else {
        return 0;
      }
    },
    // 输入字母
    shuruzimu(str) {
      this.input = this.input + str;
    },
    // 选择部门
    bumen(name, id, index, num) {
      // this.ruleForm.danwei = name;
      // this.danweiId = id;
      // this.danweixuanze = !this.danweixuanze;
      // return;

      // -1则代表没有
      let d = this.buarrname.indexOf(name.toString());
      let e = this.buarrid.indexOf(id.toString());
      // 没有则push,有则删除
      if (d == -1) {
        // 判断是否超过是个,超过则警告
        if (this.buarrname.length == 10) {
          this.$message({
            message: "最多只能选择十个部门!",
            center: true,
            type: "warning",
          });
          return;
        }
        this.buarrname.push(name.toString());
      } else {
        this.buarrname.splice(d, 1);
      }
      // console.log(this.buarrname, "手机号");
      // 没有则push,有则删除
      if (e == -1) {
        this.buarrid.push(id.toString());
      } else {
        this.buarrid.splice(e, 1);
      }

      this.ruleForm.danwei = "";
      this.danweiId = "";
      this.ruleForm.danwei = this.buarrname.toString();
      this.danweiId = this.buarrid.toString();
      // console.log(this.danweiId,'1111');

      if (this.list[index].child[num].xuan == 1) {
        this.list[index].child[num].xuan = 0;
        let c = this.list;
        this.list = [];
        this.list = c;
      } else {
        this.list[index].child[num].xuan = 1;
      }
      // console.log(this.list[index].child[num].xuan, "2023年10月24日09:54:15");
    },
    // 退格键
    tgj() {
      this.input = this.input.substring(0, this.input.length - 1);
    },
    // 受理单位
    async unitList() {
      let res = await API.unitList().then((res) => {
        if (res.code == 1) {
          this.list = res.data;
          this.list.forEach((item, index) => {
            this.zixiang(item.child);
          });
        }
      });
    },
    zixiang(obj) {
      let a = null;
      a = this.$store.state.loginMessage.bm;
      if (this.$store.state.loginMessage.oldpaths != "unit") {
        a = "";
      }
      obj.forEach((item, index) => {
        item["biaohong"] = "";
        item["xuan"] = "0";
        if (item.id == a) {
          // this.bumen(item.name, item.id);
          this.ruleForm.danwei = item.name;
          this.danweiId = item.id;
        }
      });
    },
    // 打开部门查询
    dan() {
      this.danweixuanze = true;
    },
    bili(Obj) {
      let a = [];
      // console.log(this.danweiId, "是");
      Obj.child.forEach((res, index) => {
        this.buarrid.forEach((item, num) => {
          if (res.id == item) {
            a.push(res.name);
            res.xuan = 1;
          }
        });
        // if (res.id == this.danweiId) {
        //   this.ruleForm.danwei = res.name;
        // }
      });
    },
    // 查询草稿详情
    async tiezi() {
      let that=this
      let res = await API.articleDraftinfo({
        cardid: this.$store.state.loginMessage.compile,
      }).then((res) => {
        if (res.code == 1) {
          this.wanzhang = res.data;
          this.ruleForm.leiixng = res.data.type;
          // 赋值单位id
          this.danweiId = res.data.department_ids;
          // 将id转为数组
          this.buarrid = res.data.department_ids.split(",");

          let aname = res.data.unit_name.split(",");
          this.buarrname = aname;
          this.ruleForm.danwei = res.data.unit_name;
          // 查询单位名
          this.list.forEach((item, index) => {
            this.bili(item);
          });
          this.$refs.editor.huodeGTML(res.data.content);
          this.ruleForm.state2 = res.data.title;
          this.ruleForm.lianxi = res.data.contacts;
          this.ruleForm.dianhua = res.data.contactsphone;
          this.ruleForm.xianshi = res.data.is_open;
          // 匹配所有图片标签,获得地址
          // let imgStrs = res.data.content.match(/<img.*?>/g);
          // if (imgStrs != null) {
          //   let urls = imgStrs.map((url) => {
          //     return url.match(/\ssrc=['"](.*?)['"]/)[1];
          //   });
          //   urls.forEach((item) => {
          //     this.$store.state.videImg.push({
          //       di: item,
          //       geshi: "图片",
          //       id: false,
          //     });
          //   });
          // }

          // 新版有专门的图片数组
          res.data.appletimages.forEach((item) => {
            this.$store.state.videImg.push({
              di: item.url,
              geshi: "图片",
              id: 0,
            });
          });

          // console.log(urls, '图片');
          // 匹配所有视频标签,获得地址
          // let videoStrs = res.data.content.match(/<video.*?>/g);
          // if (videoStrs != null) {
          //   let url = videoStrs.map((url) => {
          //     return url.match(/\ssrc=['"](.*?)['"]/)[1];
          //   });
          //   url.forEach((item) => {
          //     this.$store.state.videImg.push({
          //       di: item,
          //       geshi: "视频",
          //       id: false,
          //     });
          //   });
          // }
          // 新版有专门文件的数组
          res.data.appletfiles.forEach((item) => {
            this.fuzhanlist.push(item.filename);
            // this.fulist.push(item.url);
            this.fulist.push({
              filename: item.filename,
              size: item.size,
              url: item.url,
            });
          });
          // 新版有专门的视频的数组
          let that = this;
          res.data.appletvideos.forEach((item) => {
            // 获取时长
            var audio = new Audio(item.url);
            // 元数据已加载
            audio.addEventListener("loadedmetadata", function (e) {
              let duration = audio.duration;
              //计算时长
              if (duration < 60) {
                // 去除小数点
                let b = Math.trunc(duration);
                if (b < 10) {
                  b = "0" + b;
                }
                b = "00" + ":" + b;
                that.$store.state.videImg.push({
                  di: item.url,
                  geshi: "视频",
                  id: 0,
                  shi: b,
                });
              } else {
                let c = Math.trunc(duration);
                let time_s = c;
                var minute = Math.floor(time_s / 60);
                var rest_seconds = time_s % 60;
                let a = minute + ":" + rest_seconds;
                that.$store.state.videImg.push({
                  di: item.url,
                  geshi: "视频",
                  id: 0,
                  shi: a,
                });
              }
            });
          });

          // console.log(that.$store.state.videImg, "视频");
        }else{
          this.$message({
          message: '帖子不存在,即将返回首页',
          type: 'warning'
        });
        
      setTimeout(() => {
        that.$store.state.likai = 0;
        that.$router.push("/");
      }, 2000);

       

        }
      });
    },
    // 置空
    kong() {
      return;
      // let timer = setTimeout(() => {
      //     location.reload();
      // },2000)
      let tms = setTimeout(() => {
        window.history.go(-1);
      }, 2000);
      // this.cishu = this.cishu + 1
      // this.$refs.editor.huodeGTML()
      // this.$store.state.videImg = []
      // this.ruleForm.leiixng = ''
      // this.ruleForm.state2 = ''
      // this.ruleForm.lianxi = ''
      // this.ruleForm.dianhua = ''
      // this.ruleForm.xianshi = 0
      // this.ruleForm.danwei = ''
      // this.ruleForm.yanzheng = ''
      // this.ruleForm.tuxing = ''
    },
    // 删除图片
    close(num) {
      this.kuangNum.forEach((item, index) => {
        if (item == num) {
          this.kuangNum.splice(index, 1);
        }
      });
      this.$store.state.videImg.splice(num, 1);
    },
    // 验证码倒计时
    yanzheng() {
      yantiem = setTimeout(() => {
        this.daoji = this.daoji - 1;
        if (this.daoji != 0) {
          this.yanzheng();
        }
      }, 1000);
    },
    // 获取验证码
    async yanzhengma() {
       // window.localStorage.setItem('sendebang', a)
    let aaa = window.localStorage.getItem("sendebang");
    // window.localStorage.removeItem('sendebang')
    if (aaa == undefined) {
      this.$store.state.likai=0
      this.$store.state.path = this.$route.path;
      localStorage.setItem("path", this.$route.path);
      this.ruleForm.fuwenben = this.$refs.editor.GetTheHTML();
      this.$store.state.mytie.ruleForm = this.ruleForm;
      this.$store.state.mytie.danweiId = this.danweiId;
      
      MessageBox.confirm('您尚未登录，是否跳转登录?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).then(() => {
        this.$router.push("/login");
      }).catch((err) => {
      });
      return;
    }
      if (this.ruleForm.dianhua == "") {
        this.$message({
          message: "请输入手机号",
          center: true,
          type: "warning",
        });
        return;
      }
      this.isyanzhen = 1;
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.mobilelogin({
        mobile: this.ruleForm.dianhua,
        event: "posting",
      })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "验证码已发送注意查收!",
              center: true,
              type: "success",
            });
            this.loading.close();
            this.daoji = 60;
            this.yanzheng();
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              center: true,
              type: "warning",
            });
          }
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 获取区域
    async region() {
      let res = await API.unitList().then((res) => {
        if (res.code == 1) {
          res.data.forEach((item) => {
            this.options.push({ value: item.id, label: item.name });
          });
          // console.log(this.options, "2222");
        }
      });
    },
    // 手机验证码
    shouji(rule, value, callback) {
      if (value != this.shouYan) {
        callback(new Error("验证码不正确!"));
      } else {
        callback();
      }
    },
    // 图形验证
    validatePass(rule, value, callback) {
      if (value != this.identifyCode) {
        callback(new Error("验证码不正确!"));
      } else {
        callback();
      }
    },
    // 判断是否为64
    isBase64(str) {
      if (str.indexOf("data:") != -1 && str.indexOf("base64") != -1) {
        return true;
      } else {
        return false;
      }
    },
    yanbase() {
      this.cuoimg = [];
      this.cuovideo = [];
      // return 开关,为1代表存在base64
      let cuo = 0;
      // 图片
      let a = this.$refs.editor.GetTheHTML();
      // 匹配所有图片标签,获得地址
      let imgStrs = a.match(/<img.*?>/g);
      if (imgStrs != null) {
        let urls = imgStrs.map((url) => {
          return url.match(/\ssrc=['"](.*?)['"]/)[1];
        });
        // console.log(urls, "图片地址");
        urls.forEach((item) => {
          // 将base64图片选出来
          if (this.isBase64(item) == true) {
            this.cuoimg.push(item);
            cuo = 1;
          }
        });
      }
      // 匹配所有视频标签,获得地址
      let b = this.$refs.editor.GetTheHTML();
      let videoStrs = b.match(/<video.*?>/g);
      if (videoStrs != null) {
        let url = videoStrs.map((url) => {
          return url.match(/\ssrc=['"](.*?)['"]/)[1];
        });
        url.forEach((item) => {
          // 将base64视频选出来
          if (this.isBase64(item) == true) {
            this.cuovideo.push(item);
            cuo = 1;
          }
        });
      }
      if (cuo == 0) {
        return false;
      } else {
        return true;
      }
    },
    // 获取首图
    shoutu() {
      // 验证base64
      if (this.yanbase() == true) {
        return false;
      }
      this.tulist=[]
      this.shilist=[]
      // 将图片和视频分开
      this.$store.state.videImg.forEach((item) => {
        if (item.geshi == "图片") {
          this.tulist.push(item.di);
        } else {
          this.shilist.push(item.di);
        }
      });
      //没有图片内容
      if (this.$store.state.videImg.length == 0) {
        this.shoutuList = [];
        return;
      }
      this.shoutuList = [];
      // 他自己选择了图片
      if (this.kuangNum.length > 0) {
        this.kuangNum.forEach((item) => {
          this.shoutuList.push(this.$store.state.videImg[item]);
        });
        return;
      }
      // 他没有选择图片且图片低于三张
      if (this.$store.state.videImg.length <= 3) {
        this.$store.state.videImg.forEach((item) => {
          this.shoutuList.push(item);
        });
        return;
      }
      // 他没有选择图片且图片高于三张
      if (this.$store.state.videImg.length > 3) {
        // 优先图片
        this.$store.state.videImg.forEach((item) => {
          if (item.geshi == "图片") {
            this.shoutuList.push(item);
          }
        });
        // 如果图片没到三张,则获取视频首图
        if (this.shoutuList.length < 3) {
          this.$store.state.videImg.forEach((item) => {
            if (this.shoutuList.length == 3) {
              return;
            } else {
              if (item.geshi == "视频") {
                this.shoutuList.push(item);
              }
            }
          });
        }
        return;
      }
    },
    // 提交表单前验证(kkk)
    tijiaoBtn(formName) {
      // this.dialogTableVisible = true;
      // return;
      let aaa = window.localStorage.getItem("sendebang");
    // window.localStorage.removeItem('sendebang')
    if (aaa == undefined) {
      this.$store.state.path = this.$route.path;
      localStorage.setItem("path", this.$route.path);
      this.ruleForm.fuwenben = this.$refs.editor.GetTheHTML();
      this.$store.state.mytie.ruleForm = this.ruleForm;
      this.$store.state.mytie.danweiId = this.danweiId;
      this.$store.state.likai=0
      MessageBox.confirm('您尚未登录，是否跳转登录?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).then(() => {
        this.$router.push("/login");
      }).catch((err) => {
      });
      return;
    }
    if(!this.canfabu){
      return
    }

    
    if(this.ruleForm.state2.length<3 || this.ruleForm.state2.length>50){
        this.$message({
              message: '留言长度在 3 到 50 个字符',
              center: true,
              type: "warning",
            });
        return
      }
      if (this.isyanzhen == 0) {
        this.$message({
          message: "尚未发送验证码!",
          center: true,
          type: "warning",
        });
        return;
      }
      // this.cuonum = 0;
      // let tu = this.shoutu();
      // if (tu == false) {
      //   this.cuonum = 1;
      //   return;
      // }
      // // 获取富文本内容
      this.ruleForm.fuwenben = this.$refs.editor.GetTheHTML();
      // console.log("1111");
      this.$refs[formName].validate(async (valid) => {
        // console.log(valid, "什么");
        if (valid) {
          this.canfabu=false
          // 通过则打开弹窗
          let c = await this.mingan();
          if (c == false) {
            return;
          } else {
            this.dialogTableVisible = true;
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑发帖
    async updateArticleCard(num) {
      this.loading = this.$loading({
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let arr = [];
      if (this.shoutuList.length == 0) {
        arr = [];
      } else if (this.shoutuList.length == 1) {
        arr.push(this.shoutuList[0]);
      } else if (this.shoutuList.length == 2) {
        arr.push(this.shoutuList[0], this.shoutuList[1]);
      } else {
        arr.push(this.shoutuList[0], this.shoutuList[1], this.shoutuList[2]);
      }
      // this.shoutuList.forEach((item) => {
      //   arr.push(item);
      // });
      let b = [];
      this.fulist.forEach((item) => {
        b.push(item);
      });
      
      let res = await http
        .updateArticleCard({
          cardid: this.$store.state.loginMessage.compile, //草稿id
          title: this.ruleForm.state2, // 标题
          type: this.ruleForm.leiixng, // 类型:0=提问,1=投诉,2=建议
          unit_num: this.danweiId, // 单位编号
          port: 0, // 端:0=PC端,1=手机端
          images: arr, //首图
          content: this.ruleForm.fuwenben, // 内容
          contentstext: "", // 纯文本内容
          appletimages: this.tulist.toString(), //图片数组
          appletvideos: this.shilist.toString(), //视频数组
          appletfiles: b, //附件数组
          contacts: this.ruleForm.lianxi, // 联系人
          contactsphone: this.ruleForm.dianhua, // 联系电话
          is_open: this.ruleForm.xianshi, // 是否公开:0=否,1=是
          is_online: num, // 发表状态:0=上线,1=保存草稿
          captcha: this.ruleForm.yanzheng, // 验证码
        })
        .then((res) => {
          // this.$store.state.videImg = []
          this.shoutuList = [];
          if (res.code == 1) {
           
            this.dialogTableVisible = false;
            this.ishow = !this.ishow;
            // 十秒倒计时
            this.shimiao = 10;
            this.shidao();
            this.fcnum = num;
            this.caoid = res.data;
            if (num == 1) {
              this.$message({
                message: "保存成功!",
                center: true,
                type: "success",
              });
            } else {
              this.$message({
                message: "发帖成功!",
                center: true,
                type: "success",
              });
            }
            this.loading.close();
            window.localStorage.removeItem("fbmid");
            this.kong();
            // 上传成功过一次
            this.$store.state.likai = 0;
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              center: true,
              type: "warning",
            });
          }
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 十秒回归首页
    shidao() {
      shim = setTimeout(() => {
        this.shimiao = this.shimiao - 1;
        if (this.shimiao != 0) {
          this.shidao();
        }
        if (this.shimiao == 0) {
          this.huche = true;
          // this.refresh();
          this.$router.push("/persomal");
        }
      }, 1000);
    },
    // 上传表单
    async shangchuan() {
      if(!this.canfabu){
      return
    }
   
      if(this.ruleForm.state2.length<3 || this.ruleForm.state2.length>50){
        this.$message({
              message: '留言长度在 3 到 50 个字符',
              center: true,
              type: "warning",
            });
        return
      }
      this.loading = this.$loading({  
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 获取富文本内容
      this.ruleForm.fuwenben = this.$refs.editor.GetTheHTML();
      this.cuonum = 0;
      let tu = this.shoutu();
      if (tu == false) {
        this.cuonum = 1;
        this.loading.close();
        this.dialogTableVisible = false;
        return;
      }

      if (this.bianjis == 1) {
        this.loading.close();
        this.bianjis = 0;
        this.updateArticleCard(0);
        return;
      }

      let arr = [];
      if (this.shoutuList.length == 0) {
        arr = [];
      } else if (this.shoutuList.length == 1) {
        arr.push(this.shoutuList[0]);
      } else if (this.shoutuList.length == 2) {
        arr.push(this.shoutuList[0], this.shoutuList[1]);
      } else {
        arr.push(this.shoutuList[0], this.shoutuList[1], this.shoutuList[2]);
      }
      // this.shoutuList.forEach((item) => {
      //   arr.push(item);
      // });
      let b = [];
      this.fulist.forEach((item) => {
        b.push(item);
      });
      this.canfabu=false
      let res = await http
        .addArticleCard({
          title: this.ruleForm.state2, // 标题
          type: this.ruleForm.leiixng, // 类型:0=提问,1=投诉,2=建议
          unit_num: this.danweiId, // 单位编号
          port: 0, // 端:0=PC端,1=手机端
          images: arr, //首图
          content: this.ruleForm.fuwenben, // 内容
          contentstext: "", // 纯文本内容
          appletimages: this.tulist.toString(), //图片数组
          appletvideos: this.shilist.toString(), //视频数组
          appletfiles: b, //附件数组
          contacts: this.ruleForm.lianxi, // 联系人
          contactsphone: this.ruleForm.dianhua, // 联系电话
          is_open: this.ruleForm.xianshi, // 是否公开:0=否,1=是
          is_online: 0, // 发表状态:0=上线,1=保存草稿
          captcha: this.ruleForm.yanzheng, // 验证码
        })
        .then((res) => {
          this.canfabu=true
          // console.log(res, "发帖");
          this.$store.state.mytie={}
          if (res.code == 1) {
            this.dialogTableVisible = false;
            this.loading.close();
            this.ishow = !this.ishow;
            this.fcnum = 0;
            // 十秒倒计时
            this.shimiao = 10;
            this.shidao();
            // this.$message({
            //   message: "发帖成功!",
            //   center: true,
            //   type: "success",
            // });
            // this.kong();
            window.localStorage.removeItem("fbmid");
            // 上传成功过一次
            this.$store.state.likai = 0;
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              center: true,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.huche = true;
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 敏感词
    async mingan() {
      let a = true;
      let c =
        this.ruleForm.state2 +
        "," +
        this.ruleForm.fuwenben +
        "," +
        this.ruleForm.lianxi;
      let one = await API.sensitive({
        info: c,
      }).then((res) => {
        this.canfabu=true
        if (res.code == 1) {
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          a = false;
        }
      });
      if (a == false) {
        return a;
      }
      return true;
    },
    // 返回首页
    gotohome() {
      this.jump("/", this.$store.state.loginMessage);
      // this.$router.push("/");
    },
    // 继续编辑
    jb() {
      // 回到页面顶部
      clearTimeout(shim);
      this.shoutuList = [];
      this.$store.state.videImg = [];
      this.tulist = []; //上传时保存图片的数组
      this.shilist = []; //上传时保存视频的数组
      this.fujianname = ""; //附件名 附件上传前可以得到name,成功后赋给fuzhanlist数组进行展示
      this.fulist = []; //附件数组
      this.fuzhanlist = [];
      this.ishow = !this.ishow;
      this.caogaotan = true;
      window.scrollTo(0, 0);
      // 互斥锁
      this.huche = true;
      // 进入编辑状态
      this.bianjis = 1;
      // 获得草稿id
      // this.$store.state.loginMessage.compile = this.caoid;
      this.$store.state.loginMessage.compile = this.caoid;
      this.$store.state.loginMessage.EditStatus = 1;
      // this.jumps("issue", this.$store.state.loginMessage);
      // this.$router.push("/issue");
      // location.reload();
      this.tiezi();
    },
    // 重新发帖
    refresh() {
      // 移除监听
      window.removeEventListener("beforeunload", this.handleWindowClose);
      this.$store.state.loginMessage.EditStatus = 0;
      // this.jumps("issue", this.$store.state.loginMessage);
      this.$router.push("/issue");
      this.huche = true;
      location.reload();
      // this.jumps('issue', this.$store.state.loginMessage)
    },
    // 保存草稿
    async caogao() {
      let aaa = window.localStorage.getItem("sendebang");
    // window.localStorage.removeItem('sendebang')
    if (aaa == undefined) {
      this.$store.state.path = this.$route.path;
      localStorage.setItem("path", this.$route.path);
      this.ruleForm.fuwenben = this.$refs.editor.GetTheHTML();
      this.$store.state.mytie.ruleForm = this.ruleForm;
      this.$store.state.mytie.danweiId = this.danweiId;
      this.$store.state.likai=0
      MessageBox.confirm('您尚未登录，是否跳转登录?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).then(() => {
        this.$router.push("/login");
      }).catch((err) => {
      });
      return;
    }
    if(!this.canfabu){
      return
    }
   
    if(this.ruleForm.state2.length<3 || this.ruleForm.state2.length>50){
        this.$message({
              message: '留言长度在 3 到 50 个字符',
              center: true,
              type: "warning",
            });
        return
      }
      this.loading = this.$loading({
        lock: true,
        text: "保存中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 获取富文本内容
      this.ruleForm.fuwenben = this.$refs.editor.GetTheHTML();
      // console.log(this.ruleForm.fuwenben);
      // 校验
      // if (
      //   this.ruleForm.fuwenben ==
      //   '<p style="font-size:16px !important" data-we-empty-p=""><br/></p>'
      // ) {
      //   this.loading.close();
      //   this.$message({
      //     message: "请输入内容",
      //     center: true,
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (this.danweiId == "") {
      //   this.loading.close();
      //   this.$message({
      //     message: "请选择受理的单位!",
      //     center: true,
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (this.ruleForm.state2 == "") {
      //   this.loading.close();
      //   this.$message({
      //     message: "请输入留言标题!",
      //     center: true,
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (this.ruleForm.state2.length <= 2) {
      //   this.loading.close();
      //   this.$message({
      //     message: "您的标题小于三个字符!",
      //     center: true,
      //     type: "warning",
      //   });
      //   return;
      // }
      this.canfabu=false
      this.$store.state.mytie={}
      let c = await this.mingan();
      if (c == false) {
        this.loading.close();
        return;
      }

      this.cuonum = 0;
      let tu = this.shoutu();
      if (tu == false) {
        this.loading.close();
        this.cuonum = 1;
        return;
      }
      if (this.bianjis == 1) {
        this.loading.close();
        this.bianjis = 0;
        this.updateArticleCard(1);
        return;
      }

      let arr = [];
      if (this.shoutuList.length == 0) {
        arr = [];
      } else if (this.shoutuList.length == 1) {
        arr.push(this.shoutuList[0]);
      } else if (this.shoutuList.length == 2) {
        arr.push(this.shoutuList[0], this.shoutuList[1]);
      } else {
        arr.push(this.shoutuList[0], this.shoutuList[1], this.shoutuList[2]);
      }
      // this.shoutuList.forEach((item) => {
      //   arr.push(item);
      // });
      let b = [];
      this.fulist.forEach((item) => {
        b.push(item);
      });
      let fbe = this.ruleForm.fuwenben;
      if (
        fbe ==
        '<p style="font-size:16px !important" data-we-empty-p=""><br/></p>'
      ) {
        this.ruleForm.fuwenben = "";
      }
      let res = await http
        .addArticleCard({
          title: this.ruleForm.state2, // 标题
          type: this.ruleForm.leiixng, // 类型:0=提问,1=投诉,2=建议
          unit_num: this.danweiId, // 单位编号
          port: 0, // 端:0=PC端,1=手机端
          images: arr, //首图
          content: this.ruleForm.fuwenben, // 内容
          contentstext: "", // 纯文本内容
          appletimages: this.tulist.toString(), //图片数组
          appletvideos: this.shilist.toString(), //视频数组
          appletfiles: b, //附件数组
          contacts: this.ruleForm.lianxi, // 联系人
          contactsphone: this.ruleForm.dianhua, // 联系电话
          is_open: this.ruleForm.xianshi, // 是否公开:0=否,1=是
          is_online: 1, // 发表状态:0=上线,1=保存草稿
          captcha: this.ruleForm.yanzheng, // 验证码
        })
        .then((res) => {
          this.canfabu=true
          // console.log(res, "发帖");
          if (res.code == 1) {
            // return
            this.dialogTableVisible = false;
            this.ishow = !this.ishow;
            this.fcnum = 1;
            // 十秒倒计时
            this.shimiao = 10;
            this.shidao();
            this.loading.close();
            this.$message({
              message: "保存成功!",
              center: true,
              type: "success",
            });
            // 获得草稿id
            this.caoid = res.data;
            this.cishu = this.cishu + 1;
            window.localStorage.removeItem("fbmid");
            // 保存成功过一次
            this.$store.state.likai = 0;

            // this.kong();
          } else {
            this.loading.close();
            this.huche = true;
            this.$message({
              message: res.msg,
              center: true,
              type: "warning",
            });
          }
        })
        .finally((suo) => {
          this.canfabu=true
          // 都执行
          this.loading.close();
        });
    },
    // 获得富文本
    chang(res) {
      this.ruleForm.fuwenben = res;
      // console.log(this.bjq, "22323");
    },
    // 选中首图
    xuantu(num) {
      let b = 0;
      this.kuangNum.forEach((item, index) => {
        if (item == num) {
          this.$store.state.videImg[num].id = 0;
          this.kuangNum.splice(index, 1);
          b = 1;
        }
      });
      if (b == 1) {
        return;
      }
      let a = 0;
      this.$store.state.videImg.forEach((item, index) => {
        if (item.id == 1) {
          a = a + 1;
        }
      });
      if (a == 3) {
        let c = this.kuangNum[0];
        this.$store.state.videImg[c].id = 0;
        this.kuangNum.splice(0, 1);
        this.kuangNum.push(num);
        this.$store.state.videImg[num].id = 1;
      }
      if (a != 3) {
        this.$store.state.videImg[num].id = 1;
        this.kuangNum.push(num);
        // console.log(this.kuangNum, "333");
      }
      // console.log(this.$store.state.videImg, "22");
      // this.$store.state.videImg[num].id = true
    },
    // 诉求类型
    suqiu(num) {
      this.ruleForm.leiixng = num;
    },
    // 输入后带建议
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadAll() {
      return [{ value: "三全鲜食（北新泾店）", address: "长宁区新渔路144号" }];
    },
    // 获得搜索建议
    async handleSelect(item) {
      // console.log(item);
      clearInterval(jietime);
      let that = this;
      jietime = setTimeout(async () => {
        // console.log('sad加上');
        let res = await API.articleSearchWechat({
          sinfo: that.ruleForm.state2,
          page: 1,
          pageLine: 10,
        }).then((res) => {
          if (res.code == 1) {
            if (res.data.list == undefined) {
              return;
            } else {
              that.liulist = res.data.list;
            }
          }
        });
      }, 1000);
    },
  },
  // 销毁定时器
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handleWindowClose);
    window.localStorage.removeItem("fbmid");
    clearTimeout(timess);
    clearTimeout(shim);
    this.$store.state.loginMessage.EditStatus = 0;
  },
};
</script>

<style scoped lang="scss">
.issue {
  width: 1200px;
}

.biao {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  margin: 16px 0 22px 0;

  .zuojian {
    width: 18px;
    height: 18px;
    margin: 0 3px;
  }

  .yanse {
    color: #0653ac;
  }
}

// 表单
.biaodan {
  background: #ffffff;
  border: 1px solid #78a6dc;
  padding: 45px 78px 7px 29px;
  display: flex;

  .yuan {
    width: 20px;
    height: 20px;
  }

  .mr6 {
    margin-right: 6px;
  }

  .mr100 {
    margin-right: 100px;
  }

  .biaotimu {
    ::v-deep(.el-col-12) {
      width: 363px;
      height: 44px;
    }

    ::v-deep(.el-input__inner) {
      width: 363px;
      height: 44px;
      background: #ffffff;
      border: 1px solid #d6dbe3;
      border-radius: 2px;

      &::-webkit-input-placeholder {
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #484c51;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }

  .fontOne {
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;
  }

  .fwb {
    width: 100%;
    // min-height: 300px;
    position: relative;
    .gengchang {
      position: absolute;
      width: 100%;
      height: 15px;
      background: #f1f1f1;
      left: 0;
      bottom: 1px;
      z-index: 40;
      cursor: pointer;
    }
    .xuhaun {
      position: absolute;
      right: 0;
      top: 0;
      width: 10px;
      height: 10px;
      background: red;
      z-index: 40;
    }
  }

  .zhu {
    // height: 0px;
  }

  .zhuyi {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #c90e0e;
    letter-spacing: 0;
    font-weight: 400;
  }

  .tuImg {
    margin-right: 15px;
    margin-bottom: 10px;

    border-radius: 2px;
    display: flex;
    align-items: center;
    position: relative;

    .tupiandd {
      width: 160px;
      height: 90px;
      overflow: hidden;
      border: 1px solid #d6dbe3;
    }

    ._close {
      width: 18px;
      height: 18px;
      position: absolute;
      left: calc(100% - 9px);
      top: -16px;
      cursor: pointer;
    }
  }

  .lianxi {
    ::v-deep(.el-input) {
      width: 363px;
      height: 44px;
      background: #ffffff;

      border-radius: 2px;
    }

    ::v-deep(.el-input__inner) {
      border: 1px solid #d6dbe3;
      height: 44px;

      &::-webkit-input-placeholder {
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #484c51;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }

  .yanzheng {
    ::v-deep(.el-input) {
      width: 270px;
      height: 44px;
      background: #ffffff;

      border-radius: 2px;
    }

    ::v-deep(.el-input__inner) {
      border: 1px solid #d6dbe3;
      height: 44px;

      &::-webkit-input-placeholder {
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #484c51;
        letter-spacing: 0;
        font-weight: 400;
      }
    }

    ::v-deep(.el-button) {
      border: 0px solid red;
      height: 120px;
      height: 44px;
      background: #f11515;
      margin-left: 12px;
    }
    ::v-deep(.is-disabled) {
      background-color: #c8c9cc !important;
    }
    .tuxing {
      height: 120px;
      height: 44px;
      margin-left: 12px;
    }
  }
}

// 提交按钮
.tijiao {
  margin: 24px 0;

  ::v-deep(.el-button) {
    width: 240px;
    height: 48px;
    border-radius: 2px;
    border: 0px solid red;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
  }

  ::v-deep(.el-button--primary) {
    background: #1c69c6;
    margin-left: 40px;
  }
}

.bian {
  border: 3px solid #78a6dc !important;
}

::v-deep(.el-form-item__label) {
  white-space: nowrap;
  text-align: left;
}

//  弹框
::v-deep(.el-dialog) {
  width: 100vw;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0 !important;
  margin: 0 !important;
  box-shadow: none;
  // height: 180px;
}

::v-deep(.el-dialog__headerbtn) {
  top: 10px;
  right: 12px;
}

::v-deep(.el-dialog__header) {
  padding: 0 0 0 0;
  display: none;
  // width: 402px;
}

::v-deep(.el-dialog__body) {
  // padding: 47px 0 0 0;
  width: 1200px;
  // height: 180px;
  // background: #ffffff;
  border-radius: 8px;
}

.tishiyu {
  display: flex;
  justify-content: center;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #27262f;
  letter-spacing: 0;
  font-weight: 500;
  position: relative;

  .tishiicon {
    width: 20px;
    height: 20px;
    margin-right: 9px;
  }
}

.btn {
  margin-top: 32px;
  justify-content: center;

  ::v-deep(.el-button) {
    width: 120px;
    height: 44px;
    border-radius: 2px;
    border: 0px solid red;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
  }

  ::v-deep(.el-button--primary) {
    background: #1c69c6;
    margin-left: 40px;
  }
}

.danwei {
  // width: 270px;
  position: relative;
  .fudong {
    position: absolute;
    width: 363px;
    height: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  :deep(.el-input) {
    width: 363px !important;
  }
  :deep(.el-input__inner) {
    background: #ffffff;
    border: 1px solid #d6dbe3;
    border-radius: 2px;
    width: 363px;
    height: 44px;

    &::-webkit-input-placeholder {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: #484c51;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  :deep(.el-input.is-disabled .el-input__inner) {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #484c51;
    letter-spacing: 0;
    font-weight: 400;
    cursor: pointer;
  }
}

.tac {
  text-align: center;
}

.success_page {
  width: 100%;
  height: 60vh;
  flex-direction: column;
  background-color: #fff;
  z-index: 10004;

  .success_title {
    color: #ff9c70;
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
  }

  .success_thanks {
    font-size: 18px;
    margin-top: 70px;
  }

  .mt {
    margin-top: 40px !important;
  }

  .success_bianhao {
    color: #ff9c70;
    margin: 0 10px;
  }

  .success_save {
    color: #6e60ff;
    font-weight: 700;
    margin-left: 10px;
  }

  .flex {
    display: flex;
  }

  .jcc {
    justify-content: center;
  }

  .success_btn {
    margin: 0 20px;
    // width: 130px;
    padding: 0 10px;
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    margin-top: 70px;
    font-size: 20px;
    border: 1px solid #86a9df;
    color: #86a9df;
    letter-spacing: 2px;
    cursor: pointer;
  }
}

.bumencha {
  // position: fixed;
  // left: calc(50vw - 600px);
  // top: 50px;
  width: 100%;
  z-index: 1000;

  // 受理单位
  .shouli {
    margin-top: 14px;
    width: 100%;
    // background-image: url('../../assets/img/xinban/kuangBJ.png');
    // background-size: 100% 100%;
    padding: 22px 75px 13px 40px;
    box-sizing: border-box;
    position: relative;
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 17px;

    .list {
      width: 100%;
      border-bottom: 1px dashed rgba(126, 126, 126, 0.19);
      padding-bottom: 0px;
      margin-bottom: 18px;
    }

    .dalei {
      width: 150px;
      font-family: PingFangSC-Semibold;
      font-size: 15px;
      color: #0653ac;
      letter-spacing: 0;
      font-weight: 600;
    }

    .xiaofen {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #191a1c;
      letter-spacing: 0;
      font-weight: 400;
      width: 100%;
      flex-wrap: wrap;

      .gezi {
        width: 16%;
        margin-bottom: 10px;
      }

      .fontOne {
        color: #c90e0e;
      }
    }

    .chaxun {
      align-items: center;
      margin-top: 21px;
    }

    .zimu {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #484c51;
      letter-spacing: 2.02px;
      text-align: justify;
      font-weight: 400;
      margin-right: 16px;

      .mu {
        margin-right: 5px;
        cursor: pointer;
        user-select: none;
      }
    }

    //搜索
    .sousu {
      width: 220px;
      height: 36px;
      // border: 0.5px solid #CCCCCC;
      position: relative;
      border-radius: 50%;

      ::v-deep(.el-autocomplete) {
        width: 100% !important;
        height: 100% !important;
      }

      ::v-deep(.el-input) {
        height: 100% !important;
      }

      ::v-deep .el-input__inner {
        border: 1px solid #cccccc;
        height: 100%;
        background: #ffffff;
        border-radius: 17px;
      }

      ::v-deep .el-input__inner::-webkit-input-placeholder {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #8890a2;
        letter-spacing: 0;
        font-weight: 400;
      }

      .fang {
        padding: 0 0 0 18px;
        position: absolute;
        right: 1vw;
        top: calc(50% - 10px);
        border-left: 1px solid #2670c5;
        height: 19px;
      }

      .fangda {
        width: 20px;
        height: 20px;
        background-image: url("~@/assets/img/xinban/tg.png");
        background-size: 100% 100%;

        cursor: pointer;
      }
    }

    .fontThree {
      font-family: PingFangSC-Regular;
      font-size: 13px;
      color: #0653ac;
      letter-spacing: 1px;
      font-weight: 400;
    }

    .zhankai {
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }
  }
}

.biaohong {
  color: #c90e0e !important;
  font-weight: 700;
}

// 富文本上传弹框
.shangchua {
  z-index: 10003;
  position: fixed;
  left: calc(50% - 292px);
  top: 20%;
  width: 584px;
  // height: 500px;
  background: #fff;
  border-radius: 4px;
  padding: 10px 0 20px 0;
  box-sizing: border-box;
  border: 7px solid rgba(0, 0, 0, 0.2);

  .tou {
    border-bottom: 1px solid #eeeef1;
    position: relative;
    width: 100%;
    height: 30px;
    padding: 0 20px 0 0;
    box-sizing: border-box;

    .closeg {
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: #ff1432;
      }
    }
  }

  .fuqilai {
    position: absolute;
    left: 10px;
    top: 0px;
  }

  .shangchuan {
    padding: 0px 20px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid #eeeef1;
    background: #fff;
    line-height: 30px;
    cursor: pointer;
    text-align: center;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #2c2a36;
    letter-spacing: 0;
    font-weight: 400;
    background: #f6f6f6;
  }
}

.shenti {
  width: 100%;
  padding: 10px 0px 10px 0;
  box-sizing: border-box;

  // overflow: hidden;

  .tupianka {
    width: 100%;
    min-height: 50px;
  }

  ::v-deep(.el-button--small) {
    padding: 5px 8px;
    box-sizing: border-box;
    background: #ff5131;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #fff;
    letter-spacing: 0;
    text-align: justify;
    font-weight: 400;
    border-radius: 10px;
    width: 88px;
    white-space: nowrap;
    margin-left: 20px;
    cursor: pointer;
    border: 0px;
  }

  .hbss {
  }
}

.dibuka {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0 20px;
  box-sizing: border-box;
  margin-top: 10px;

  .jinggao {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;
  }
  .beizhu {
    color: #66ccff !important;
    font-weight: 700;
  }
  .kabtn {
    width: 120px;
    height: 44px;
    background: #1c69c6;
    border-radius: 2px;
    line-height: 44px;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    cursor: pointer;
  }
}

.meixia {
  border-bottom: 0px !important;
  border-radius: -4px;
  background: #fff !important;
}

.kazhan {
  width: 100%;
  flex-wrap: wrap;
  padding: 0 0 0 10px;
  box-sizing: border-box;
  margin-top: 20px;
  max-height: 400px;
  overflow-y: auto;
}

.katu {
  width: 110px;
  margin: 0 20px 10px 0;

  .tubufen {
    width: 100%;
    height: 110px;
    display: flex;
    align-items: center;
    border: 1px solid #eeeef1;

    img {
      max-height: 110px;
      // border: 1px solid #EEEEF1;
    }

    video {
      max-height: 110px;
    }
  }

  .tushan {
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #27262f;
    letter-spacing: 0;
    font-weight: 400;

    &:hover {
      color: #ff1432 !important;
    }
  }
}

.kafijian {
  display: flex;
  margin-bottom: 10px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  padding: 0 0 0 10px;
  box-sizing: border-box;

  .wenshan {
    text-align: center;
    cursor: pointer;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #27262f;
    letter-spacing: 0;
    font-weight: 400;
    margin-left: 15px;

    &:hover {
      color: #ff1432 !important;
    }
  }
}

.daojishi {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  justify-content: center;
}

.demo-ruleForm {
  flex: 7;
}

.guanjian {
  flex: 3;
  display: flex;
  justify-content: flex-end;
  padding: 60px 0 0 0;
  box-sizing: border-box;

  .xiangguan {
    width: 90%;
    border-left: 1px solid #d6dbe3;
    border-right: 1px solid #d6dbe3;
    border-top: 1px solid #d6dbe3;
    height: fit-content;
    box-shadow: 3px 3px rgba(80, 85, 119, 0.25);
    .liuyan {
      padding: 10px 20px;
      font-family: PingFangSC-Semibold;
      font-size: 16px;
      color: #2c2a36;
      letter-spacing: 0;
      font-weight: 400;
      border-bottom: 1px solid #d6dbe3;
    }

    .yihang {
      width: 100%;
      border-bottom: 1px solid #d6dbe3;
      display: flex;
      align-items: center;
      padding: 10px 10px;
      box-sizing: border-box;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #2c2a36;
      letter-spacing: 0;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        font-weight: 700;
      }

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.fwbgaodu {
  height: 340px !important;
}
.yulanbtn {
  cursor: pointer;
  color: #0653ac;
  font-size: 14px;
  &:hover {
    font-weight: 700;
  }
}
.zhaozi {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.7) !important;
  z-index: 100;
  display: flex;
  justify-content: center;
  padding: 40px 0 0 0;
  box-sizing: border-box;
  .carddd {
    width: 1200px;
    position: relative;
    .closeeee {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      z-index: 10;
      font-size: 20px;
    }
  }
}

// 预览样式
.xianq {
  // border-bottom: 4px solid #F1F1F1;
}

.xian_head {
  min-height: 58px;
  background: #ffffff;
  border-bottom: 4px solid #f1f1f1;

  .zuo {
    width: 227px;
    // height: 100%;
    border-right: 3px solid #d6dbe3;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #484c51;
    letter-spacing: 0;
    font-weight: 400;

    .mr33 {
      margin-right: 33px;
    }

    .yanse {
      color: #fb8a2b;
    }
  }

  .you {
    padding: 5px 23px;
    width: calc(100% - 227px);
    .zixu {
      font-family: PingFangSC-Regular;
      font-size: 18px;
      letter-spacing: 0;
      font-weight: 400;
      // white-space: nowrap;

      .zi {
        color: #34348f;
      }

      .lv {
        color: #00a576;
      }

      .hong {
        color: #ff5637;
      }
    }

    .danweis {
      font-family: PingFangSC-Regular;
      font-size: 18px;
      color: #51565c;
      letter-spacing: 0;
      font-weight: 400;
      // white-space: nowrap;
    }

    .biaoti {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #18191a;
      letter-spacing: 0;
      font-weight: 500;
    }

    .shouli {
      width: 61px;
      height: 19.7px;
      margin-left: 5px;
    }
  }
}

.louzhu {
  background: #ffffff;
  min-height: 364px;
  border-bottom: 4px solid #f1f1f1;

  .zuo {
    width: 227px;
    border-right: 3px solid #d6dbe3;
    padding: 40px 0 0 0;
    background: #f4f8fd;
    flex-direction: column;
    align-items: center;
    position: relative;

    .fyrbiaoji {
      position: absolute;
      left: 0;
      top: 0;
      width: 75px;
      height: 25px;
    }

    .louzhubiaoji {
      position: absolute;
      right: -1px;
      top: 0;
      width: 43.73px;
      height: 40.06px;
    }

    .touxinag {
      width: 110px;
      height: 110px;
      background: #fff;
      border: 1px solid #d6dbe3;
      padding: 5px;
      cursor: pointer;
    }

    .ming {
      margin-top: 8px;
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: #34348f;
      letter-spacing: 0;
      font-weight: 500;
    }

    .ziliao {
      margin-top: 17px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #27262f;
      letter-spacing: 0;
      font-weight: 400;

      .fontone {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #484c51;
        letter-spacing: 0;
        font-weight: 400;
      }

      .xian {
        width: 1px;
        height: 16px;
        border-left: 1px solid #d6dbe3;
        margin: 0 30px;
      }
    }

    .mt15 {
      margin-top: 15px;
    }

    .dian {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin-right: 5px;
      background: #595e64;
    }

    .fontwo {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #484c51;
      letter-spacing: 0;
      font-weight: 400;
    }

    .guanzhu {
      margin-left: 17px;
      width: 62px;
      height: 26px;
      border-radius: 17px;
      border: 1px solid #868d9d;
      text-align: center;
      line-height: 26px;
      cursor: pointer;
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: #858ea0;
      letter-spacing: 0;
      font-weight: 500;
    }

    .yiguan {
      margin-left: 17px;
      width: 62px;
      height: 26px;
      border-radius: 17px;
      border: 1px solid #fb8a2b;
      text-align: center;
      background: #fb8a2b;
      line-height: 26px;
      cursor: pointer;
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: #fff;
      letter-spacing: 0;
      font-weight: 500;
    }
  }

  .you {
    .fabuxinxi {
      width: 100%;
      height: 35px;
      padding: 0 19px 0 32px;
      border-bottom: 1px dashed transparent;
      background: linear-gradient(#fff, #fff) padding-box,
        repeating-linear-gradient(
          -45deg,
          #d6dbe3 0,
          #d6dbe3 0.3em,
          #fff 0,
          #fff 0.6em
        );
      /* 0.3em设置虚线点的宽度或长度；0.6em设置虚线点的间距 */
    }

    .fontone {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #484c51;
      letter-spacing: 0;
      font-weight: 400;
    }

    .fontTwo {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #27262f;
      letter-spacing: 0;
      font-weight: 400;
    }

    .wenzhanzhuti {
      padding: 19px 25px 21px 33px;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      ::v-deep(img) {
        max-width: 915px !important;
      }
    }

    .mt33 {
      margin-top: 33px;
    }

    .guangfang {
      padding: 7px 26px;
      background: #e8effa;
      border-radius: 2px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #0653ac;
      letter-spacing: 0;
      font-weight: 400;
    }

    .caozuo {
      margin-top: 65px;
      justify-content: space-between;
    }

    .bianju {
      margin-right: 20px;
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #484c51;
      letter-spacing: 0;
      font-weight: 400;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 3px;
      }

      .yanse {
        color: #2670c5;
      }
    }
  }
}
.gund {
  max-height: 90vh;
  overflow-y: auto;
}
.yangshi {
  font-family: PingFangSC-Semibold;
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  margin-left: 5px;
  .zy {
    color: #f26c4f !important;
  }
}
.lanzi {
  color: #2670c5;
  font-weight: 700;
}
.huise {
  background-color: #c8c9cc !important;
}
.zuiyou {
  // float: left;
}
::v-deep(.el-button--info) {
  background-color: #909399 !important;
}
font[size="2"] {
  font-size: 14px !important;
}
font[size="6"] {
  font-size: 36px !important;
}
.closee {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  cursor: pointer;
}
.fabuqueding {
  position: relative;
  :deep(.el-dialog) {
    margin-top: 15vh !important;
  }
  :deep(.el-dialog__header) {
    padding: 20px 20px 10px !important;
  }
  :deep(.el-dialog__body) {
    width: 402px !important;
    height: 180px !important;
    background: #ffffff;
    border-radius: 8px;
  }
  .closeeee {
    position: absolute;
    top: -15px;
    right: 1px;
    cursor: pointer;
    z-index: 10;
  }
}
.dabaibai {
  width: 500px;
  border-radius: 24px;
  padding: 30px 10px 20px 20px;
  box-sizing: border-box;
  background: #fff;
  min-height: 300px;
  max-height: 700px;
  overflow-y: auto;
  height: fit-content;
  position: relative;
  .cuofont {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #c90e0e;
    letter-spacing: 0;
    font-weight: 500;
  }
  .cuotu {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .cuoshi {
    width: 200px;
    height: 150px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eeeef1;
  }
  .closeeee {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 10;
    font-size: 18px;
    cursor: pointer;
  }
}
.dabaibai::-webkit-scrollbar {
  width: 3px !important;
}
.tishi {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #c90e0e;
  letter-spacing: 0;
  font-weight: 400;
  margin-left: 18px;
  position: relative;
  display: flex;
  width: 200px;

  .tips {
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: normal;
  }
}
::v-deep(.el-image__inner) {
  max-height: 110px !important;
  width: auto !important;
}
.xuantuan {
  height: 100%;
  margin-right: 10px;
  ::v-deep(.el-checkbox) {
    height: 100%;
  }
}
.quancharu {
  margin-top: 10px;
  .anniu {
    margin-left: 20px;
  }
}
</style>